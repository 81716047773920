.message-form {
  display: flex;
}

.message-sedner-toltip {
  padding-top: 35px;
}

.save-template {
  display: flex;
  min-width: 510px;
  flex-direction: row-reverse;
}