.spin-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--main-color-white-rgb), 0.8);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.center p {
  font-weight: bold;
  font-size: 12px;
}
#spinner_background p{
 margin-top:30px;
}