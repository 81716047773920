.selected {
  background-color: var(--main-color-purple-ligth) !important;
  color: var(--main-color-white) !important;
  border: 1px solid var(--main-color-purple-ligth) !important;
  border-bottom: 1px solid rgba(var(--main-color-black-rgb),.2) !important;  
}


.unselected {
  background-color: none;
}
