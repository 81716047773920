.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.content-title > p {
  margin: 0;
  font-weight: bold;
  color: var(--main-color-purple);
}

.content-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.name-table-history{
  max-width: 180px;
  word-break: break-word;    
}

.dashboard-buttons {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 0;
}

.dashboard-button { 
  padding: 2px 15px;
  background: rgba(var(--main-color-gray-rgb),.2);
  color: var(--main-color-purple);
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
}
.table-history{
  border: 1px solid #f0f0f0;
  border-left: 4px solid var(--main-color-purple);
}
.table-history-header{
  border-bottom: 1px solid #f0f0f0;
}
.table-history-header th{
  padding: 15px 25px;
}
.table-history-body td{
  padding: 5px 25px;
}
.table-history-button{
  background: transparent;
  border:0 !important;
}
.table-history-button:hover{
  background: transparent;
  box-shadow: none;
}
