.card-title {
  display: flex;
}

.card-title > p {
  margin: 0;
}

.dashboard-card-spinner {
  margin: 5px;
  width: 100%;
}

.dashboard-body-card-single {
  grid-column: auto
}

.dashboard-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-card-body > p {
  text-align: center;
  font-size: 2.2em;
  line-height: normal;
  margin: 0;
  font-weight: 300;
  color: var(--main-color-gray);
}

.dashboard-card-body > h1 {
  font-size: 4.5rem;
  font-weight: 300;
  margin: 0;
}

.dashboard-card-body > p > span {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--main-color-purple);
}

.dashboard-card-body-period {
  font-size: 20px !important;
  font-weight: bold !important;
  color: var(--main-color-purple) !important;
}

.dashboard-card-body-report-button {
  padding: 10px 80px;
  background: var(--main-color-orange);
  color: var(--main-color-purple);
  margin-top: 20px;
}

.dashboard-card-body-balance-button {
  margin-top: 40px;
  padding: 4px 50px;
  background: rgba(var(--main-color-gray-rgb),.2);
  color: var(--main-color-purple);
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
}

