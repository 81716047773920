@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&display=swap');

:root {
  --main-color-white: #FFFFFF;
  --main-color-white-rgb: 255, 255, 255;
  --main-color-gray: #808080;
  --main-color-gray-rgb: 128, 128, 128;
  --main-color-gray-ligth: #C9C3C9;
  --main-color-gray-ligth-rgb: 201, 195, 201;
  --main-color-purple: #052656;
  --main-color-purple-rgb: 94, 71, 94;
  --main-color-purple-ligth: #0092FF;
  --main-color-purple-gradient: linear-gradient(90deg, rgba(133,98,133,1) 0%, rgba(140,104,140,1) 50%, rgba(110,82,110,1) 100%);
  --main-color-black: #444444;
  --main-color-black-rgb: 68, 68, 68;
  --main-color-black-gradient: linear-gradient(90deg, rgba(77,77,77,1) 0%, rgba(77,77,77,1) 50%, rgba(51,51,51,1) 100%);
  --main-color-orange: #0092FF;
  --main-color-orange-rgb: 255, 159, 0;;
  --main-color-orange-gradient: #450554;
  --main-color-orange-ligth: #FFFF00;
  --main-color-red: #BF4D47;
  --main-color-red-rgb: 191, 77, 71;
  --main-color-green: #006C77;
  --main-color-green-rbg: 0, 105, 120;
  --main-color-pink: #FFF2F0;
  --main-color-pink-rbg: 255, 242, 240;
  --main-color-violet: #8D529B;
  --main-color-magenta: #B991C6;
  --main-color-button-primary: #450554;
  --main-color-button-secondary: #AE4AC6;
  --main-color-graphic-primary: #61E5E5;  
  --main-color-graphic-secondary: #E22750;  
}

:root {
  --body-background: #FFFFFF;
  --font-family: 'Lato', sans-serif;
  --font-size-base: 16px;
  --primary-color: #0092FF;
  --success-color: #052656;
  --warning-color: #0092FF;
  --error-color: #BF4D47;
  --heading-color: #333333;
  --text-color: #555555;
  --text-color-secondary: #052656;
  --disabled-color: #cccccc;
  --border-radius-base: 2px;
  --link-color: #999999;
  --border-color-base: var(--primary-color);
  --layout-header-background: #fff;
  --card-head-background: #0092FF;
}
 


/*upload*/
.ant-upload-text{
  color: var(--main-color-violet) !important;
}
/* Aplicando variables a estilos espec�ficos */
.ant-layout-content .ant-col{ 
  margin: 0; 
  font-size: 16px;
  font-family: "Lato" !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
}
 
.ant-btn-primary {
  background-color: var(--main-color-orange);
  border-color: var(--main-color-orange);
}

.ant-btn:hover{
  color: var(--main-color-orange) !important;
}
.ant-btn-primary:hover {
  background-color: var(--main-color-orange) !important;
  color: var(--main-color-white) !important;
  opacity: 0.8;
}
 
.ant-layout-header {
  background-color: var(--layout-header-background);
}
.header .ant-btn:hover{
  color: var(--main-color-orange) !important;
}
 
.ant-card-head {
  background-color: var(--card-head-background);
}


 
/* Otras personalizaciones */
:where(.css-dev-only-do-not-override-5wsri9).ant-popconfirm .ant-popconfirm-message >.ant-popconfirm-message-icon .anticon, :where(.css-5wsri9).ant-popconfirm .ant-popconfirm-message >.ant-popconfirm-message-icon .anticon{
  background-color: var(--main-color-white) !important;
  border-color: var(--main-color-violet) !important;
  color: var(--main-color-violet) !important;
}
.user-item {
  color: var(--text-color);
}

.ant-layout-sider .ant-layout-sider-trigger{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-color-purple);
    color: var(--main-color-orange-ligth);
    height: 57px;
}

.ant-layout-sider .ant-layout-sider-children{
    height: calc(100vh - 95px);
}


/*Inicio de estilos index*/
.ant-card{  
  border-radius: 20px;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #fff;
  transition: all .2s;
}

/*------- Start General Styles -------*/
html {
  font-family: 'Lato', sans-serif;
}

body{
  overflow: hidden;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  color: var(--main-color-purple) !important;
  font-weight: 600;
}

/* @import '~@fortawesome/fontawesome-free/css/all.css'; */

:where(.css-dev-only-do-not-override-5wsri9).ant-alert-warning, :where(.css-dev-only-do-not-override-5wsri9).ant-alert-warning .ant-alert-icon, :where(.css-5wsri9).ant-alert-warning, :where(.css-5wsri9).ant-alert-warning .ant-alert-icon{
  background: transparent;
  color: var(--main-color-purple);
}

a {
  color: var(--link-color);
  background-color: transparent; 
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
a:hover{
  color: var(--link-color);
  opacity: 0.8;
}

.ant-pagination .ant-pagination-item {
  border-radius: 0;
  min-width: 32px;
  font-family: "Lato";
  text-align: center;
  list-style: none;  
  border: 1px solid var(--main-color-orange);
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination .ant-pagination-next, .ant-pagination .ant-pagination-prev{
  line-height: inherit;
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link, .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: var(--body-background);
  border: 1px solid var(--main-color-orange);
  border-radius: 2px;
  outline: none;
  transition: all .3s;  
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link:hover, .ant-pagination .ant-pagination-prev .ant-pagination-item-link:hover {
  background-color: var(--body-background);
  border: 1px solid var(--main-color-orange);  
  color: var(--main-color-orange);  
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  background-color: var(--body-background);
  border-color: var(--main-color-orange);  
}
.ant-pagination .ant-pagination-item:hover a{
  color: var(--main-color-orange);  
}
.ant-pagination .ant-pagination-item-active a{
  color: var(--main-color-orange);  
}
.ant-pagination .ant-pagination-item-active:hover{
  border-color: var(--main-color-orange);  
}
.ant-pagination .ant-pagination-item-active:hover a{
  color: var(--main-color-orange);  
}
.ant-menu .ant-menu-submenu-selected .ant-menu-submenu-open .ant-menu-submenu-title{
  color: var(--main-color-orange);  
}

.ant-card .ant-card-head{  
  border-radius: 0;
}
.ant-switch.ant-switch-checked{
  background-color: var(--main-color-orange);
}
.ant-menu .ant-menu-item{
  border-radius: 0;
  color: var(--main-color-purple);
}
.ant-menu .ant-menu-submenu .ant-menu-submenu-title{
  color: var(--main-color-purple);
}

/*-----Card performance-----*/
.ant-card .ant-card-body{
  padding:15px;
}
.ant-card .ant-card-head{
  padding:0 15px;
}

.user-header{
  justify-content: end;
  margin-right: 20px;
}
.lengauage-selector{
  margin-right:20px !important;
}
.user-header .user-item{
  align-items: center;
  display: flex;
  margin-left: 20px; 
}
.user-header .user-item-character{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 146px;
  white-space: nowrap; 
}
.user-header .user-item .item-icon{
  margin-right: 10px;
}

.user-header .user-box{
  max-width: 100%;
}

.yp-workspace {
  margin: 10px;
}

.yp-bakground-gray {
  background-color:  rgba(var(--main-color-gray-rgb), 0.18);
}

.yp-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yp-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
}

.yp-grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

.yp-margin-top-25{
  margin-top: 25px !important;
}

.yp-workspace {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 157px);
  padding: 40px 40px 20px 40px;
  margin: 0;
  border: 1px solid rgba(var(--main-color-black-rgb),.2);
  overflow-y: scroll;
}

.yp-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.yp-content-white {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--main-color-white);
}

.yp-aling-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yp-strong-value {
  font-size: 30px;
  color: var(--main-color-purple);
  margin: 0;
}

.yp-strong-value-small {
  font-size: 15px;
  margin: 0;
  color: var(--main-color-gray);
}

.yp-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.yp-subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.yp-mt-1 {
  margin-top: 5px;
}

.yp-mt-2 {
  margin-top: 10px;
}

.yp-mt-3 {
  margin-top: 20px;
}

.yp-mt-4 {
  margin-top: 40px;
}

.yp-mb-1 {
  margin-bottom: 5px;
}

.yp-mb-2 {
  margin-bottom: 10px;
}

.yp-mb-3 {
  margin-bottom: 20px;
}

.yp-mb-4 {
  margin-bottom: 40px;
}

.ant-card-head-title {
  padding: 6px 0 !important;
}

.ant-card-extra {
  padding: 12px 0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--main-color-purple) !important;
}

.ant-input { 
  border: 1px solid var(--main-color-purple) !important;
}

.ant-picker { 
  border: 1px solid var(--main-color-purple) !important;
}

.ant-btn {
  border: 1px solid var(--main-color-purple) !important;
 }

.ant-input-number {
  border: 1px solid var(--main-color-purple) !important;
}

.ant-menu li svg{
  margin-right: 10px;
  width: 16px !important;
}
#menu-item-2-- span{
  margin-left: 0;
}
[data-menu-id="rc-menu-uuid-93709-1-menu-item-1--"] { 
  background-color: lightblue;
  color: white;
  font-weight: bold;
}
[data-menu-id="rc-menu-uuid-35513-1-menu-item-1--"] {  
  background-color: lightblue;
  color: white;
  font-weight: bold;
}
.ant-menu .ant-menu-submenu-selected .ant-menu .ant-menu-item-selected svg{
  color: var(--main-color-orange);
}
.reports + li .ant-menu-submenu-title{
  padding-left:53px !important; 
}
.reports + li .ant-menu-submenu-title:before{ 
  content: '\f2b9';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 24px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px;  
}

.reports + li + li .ant-menu-submenu-title{
  padding-left:53px !important; 
}
.reports + li + li .ant-menu-submenu-title:before{
  content: '\f802';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 24px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}

.reports + li .ant-menu-item{ 
  padding-left:74px !important; 
}
.reports + li .ant-menu-item:before{
  content: '\f03a';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}

.reports + li .ant-menu-item.ant-menu-item-selected:before{ 
  color: var(--main-color-orange);
}

.reports + li .ant-menu-item{
  padding-left:78px !important; 
}
.reports + li .ant-menu-item + .ant-menu-item:before{
  content: '\f46d';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}

.reports + li + li .ant-menu-item + .ant-menu-item.ant-menu-item-selected:before{
  color: var(--main-color-white);
}
.reports + li + li .ant-menu-item{
  padding-left:74px !important; 
}
.reports + li + li .ant-menu-item:before{
  content: '\f0c5';
  display: block;
  font-family: "FontAwesome", sans-serif;
  height: 16px; 
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  bottom: 40%;
  width: 16px; 
}


.reports + li + li .ant-menu-item.ant-menu-item-selected:before{
  color: var(--main-color-orange);
}

.ant-layout-sider-collapsed a{
  color: transparent !important;
}
.ant-layout-sider-collapsed .ant-menu-title-content span{
  color: transparent !important;
}

.ant-layout-sider-collapsed .reports + li .ant-menu-submenu-title:before{
  left: 33px;
}
.ant-layout-sider-collapsed .reports + li + li .ant-menu-submenu-title:before{
  left: 33px;
} 




.text-shortlink{
  max-width: 845px;
  padding: 20px;
}
/*.ant-menu-item-only-child{
  padding-left:47px !important;
  position: relative;
}

.ant-menu-item-only-child::before{
  width: 16px; 
  height: 16px; 
  background-image: url('assets/resources/document.svg');
  background-repeat: no-repeat; 
  display: block;
  content: '';
  position: absolute;
  left: 24px;
}
.ant-menu-item-only-child.ant-menu-item-selected::before{
  display: none;
}
.ant-menu-item-only-child:hover::before{
  width: 16px; 
  height: 16px; 
  background-image: url('assets/resources/documenthover.svg');
  background-repeat: no-repeat; 
  display: block;
  content: '';
  position: absolute;
  left: 24px;
}*/

#spinner_background p{
  margin-top:30px;
}

aside .ant-menu-root {
  margin-top: 27px;
  max-height: 80vh;
  overflow-x: hidden;    
  overflow-y: auto;
}
.bar-graph canvas{
  width: 100% !important;
}
.ant-steps-icon{
  width: 32px;
}

.wildcard-message{
  background-color: var(--main-color-purple);
  border-radius: 10px;
  color: var(--main-color-white);
  display: inline-flex;  
  margin-bottom: 2px;
  padding: 4px 10px;
  text-align: left;  
}


/*------- End General Styles -------*/

/*------- Start Scrollbar -------*/

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-color-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-color-gray);
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--main-color-gray-rgb), 0.5)
}

/*------- End Scrollbar -------*/

/*---------antd checkbox-----------*/

.ant-checkbox .ant-checkbox-inner,.ant-checkbox .ant-checkbox-inner:hover,.ant-checkbox .ant-checkbox-inner:focus{
  border-color: rgba(var(--main-color-gray-rgb), 0.5) !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--main-color-purple) !important;
  border-color: var(--main-color-purple) !important;
}
.ant-checkbox-checked::after {
  border-color: var(--main-color-purple) !important;}

/*---------antd checkbox----------*/

/*------- Start Notification -------*/
.notification-error, .notification-warning, .notification-ok{
  border-radius: 10px;
}
.notification-ok .ant-notification-notice-icon{
  transform: rotate(180deg);
}

.notification-error .ant-notification-notice-message, .notification-warning .ant-notification-notice-message{
  color: var(--main-color-white) !important;
}
.notification-error .ant-notification-notice-description, .notification-warning .ant-notification-notice-description{
  color: var(--main-color-white) !important;
}
.notification-error .ant-notification-notice-close, .notification-warning .ant-notification-notice-close{
  color: var(--main-color-white) !important;
}

.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
 
  outline: none;
}

/*------Cancel button------*/
#templates-list-create-campaign-button, #edit-template-cancel-button, #create-template-list-button, .ant-popconfirm-buttons .ant-btn-color-default, .form-create-contact .ant-btn-color-optional, #create-account-cancel-button, #edit-account-cancel-button{
  border: 1px solid var(--main-color-button-primary) !important;
  color: var(--main-color-button-primary) !important;
  transition-duration: 0.4s;
}

#templates-list-create-campaign-button:hover, #edit-template-cancel-button:hover, #create-template-list-button:hover, .ant-popconfirm-buttons .ant-btn-color-default:hover, .form-create-contact .ant-btn-color-optional:hover, #create-account-cancel-button:hover, #edit-account-cancel-button:hover{  
  border-color: var(--main-color-button-secondary) !important;
  color: var(--main-color-button-secondary) !important;
}

#create-template-create-button, .ant-popconfirm-buttons .ant-btn-color-primary, .form-create-contact .ant-btn-color-primary, #create-account-confirm-button, #edit-account-confirm-button{
  background: var(--main-color-button-primary); 
  color:var(--main-color-white) !important;
  transition-duration: 0.4s;
}

#create-template-create-button:hover, .ant-popconfirm-buttons .ant-btn-color-primary:hover, .form-create-contact .ant-btn-color-primary:hover, #create-account-confirm-button:hover, #edit-account-confirm-button:hover{
  background: var(--main-color-button-secondary) !important;
  border: 1px solid var(--main-color-button-primary) !important;
}


/*------- End Notification -------*/

/*------- Start Modal -------*/

.ant-modal .ant-modal-header {
  background: var(--main-color-purple) !important; 
  border-radius: 2px 2px 0 0;
  padding: 16px 24px;
}
.ant-modal .ant-modal-body{
  padding:24px;
}
.ant-modal .ant-modal-footer{
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  padding: 10px 16px;
  text-align: right;  
}
.ant-modal .ant-modal-close{
  color: var(--main-color-white);
  margin:0;
}

.ant-modal-header > .ant-modal-title {
  color: var(--main-color-white) !important;
}
.ant-modal .ant-modal-content{
  border-radius: 2px;
  padding:0;
}

/*------- End Modal -------*/


/*------- Start border Menu -------*/

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none !important;
}

/*------- End border Menu -------*/

/*------- Start Logo -------*/

.logo {
  border: 0 !important;
  padding: 5px;
  width: 90%;
}

/*------- End Logou -------*/

/*------- Start selected card view -------*/

.selected-card-view {
  display: flex !important;
  align-items: center;
  flex-direction: row-reverse;
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
}

/*---tooltip----*/
.ant-tooltip .ant-tooltip-inner{
  border-radius: 10px;
}

/*------- End selected card view -------*/

/*------- Star subheader -------*/

.subheader {
  display: none;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  padding: 5px 0px 5px 10px;
}

/*------- End subheader -------*/

/*------- Start Step Ant Component custom colors -------*/
.ant-steps .ant-steps-item-wait .ant-steps-item-icon{
  background-color: var(--main-color-white);
  border-color: #cccccc;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon{
  color: #cccccc;
  font-size: 18px;
}
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
  color: var(--main-color-purple);
  font-size: 18px;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
  color:var(--heading-color);
  font-size: 18px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon{
  background-color: var(--main-color-orange);
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--main-color-green) !important; 
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--main-color-white) !important;
}

.ant-drawer-content-wrapper{
  width: 840px !important;
}
.ant-drawer-header{
  background: var(--main-color-purple);
  color:  var(--main-color-white);
}
.ant-drawer .ant-drawer-header-title{
  flex-direction: row-reverse;
}
.ant-drawer .ant-drawer-close{
  color: var(--main-color-white);
}
.ant-drawer .ant-drawer-close:hover{
  background: transparent;
  color: rgba(0,0,0,.75); 
}
.ant-drawer .ant-drawer-close:hover svg{
  opacity:0.5;
}

.ant-steps-item-icon{
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-steps-item-container{
  display: flex !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--main-color-purple) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--main-color-purple) !important;
}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color: var(--main-color-orange) !important;
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
  background-color: transparent;
  color: var(--main-color-orange);
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
  background-color: transparent;
  color: var(--main-color-orange);
}

/*------- End Step Ant Component custom colors -------*/



/*------- Start Ant menu links -------*/


.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f7f2f2 !important;  
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: var(--main-color-orange) !important;
  text-decoration: underline;
}

/*------- End Ant menu Checked -------*/
.ant-radio-wrapper .ant-radio-inner{
  border-color: var(--main-color-orange);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  border-color: var(--main-color-orange);
  background-color: transparent;
  position: relative;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner:after{
  background: var(--main-color-orange);
  display: block;
  height: 8px;
  left: 11px;
  opacity: 1;
  position: absolute;
  transform: scale(1);  
  transition: all .3s cubic-bezier(.78,.14,.15,.86);
  top: 11px;
  width: 8px;
}

/*------- Start Chatbot -------*/

.chatBot {
  position: fixed;
  bottom:7px;
	right:20px;
}

.chatBot-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--main-color-purple);
  color: var(--main-color-white);
  cursor: pointer;
  box-shadow: inset -1px 3px 8px 5px var(--main-color-purple), 2px 5px 16px 0px var(--main-color-gray), 5px 5px 7px 5px rgba(151,255,181,0);
}

.chatBot-button:hover {
  background: rgba(var(--main-color-purple-rgb),.8);
  border: 1pxx solid rgba(var(--main-color-black-rgb),.2);
}

.chatBot-button:active {
  background: var(--main-color-purple);
}

.ant-menu-item-selected svg{
  color: var(--main-color-orange);
}

/*------- Spin -------*/
.ant-spin-dot-item {
  background-color: var(--main-color-orange) !important;
}

/*------- End Chatbot -------*/

/*-----start dragger-------*/
.ant-upload.ant-upload-drag{
  border: 3px dashed rgba(var(--main-color-gray-rgb), 0.5)  !important;

}
/*------Upload--------*/
.ant-upload-list-item{
  width: 45%;
}

/*------End dragger-----*/


/*-----Copy code of code block-------*/
.eTSKtV {
  background: var(--main-color-white) !important;
  color: var(--main-color-purple) !important;
}
/*-----Table-------*/
table{
  border-radius: 10px !important;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
}
table tr td{
  border: 0 !important;
}
table thead tr th:first-child {
  border-radius: 10px 0 0 0;
}
table thead tr th:last-child {
  border-radius: 0 10px 0 0;
}
table thead tr:last-child td .workspace-card-left {
  border-radius: 0 0 0 10px;
}
#table-border table thead tr th{
  border-radius: 10px 10px 0 0 !important;
}
#table-border-secondary table thead tr th:first-child{
  border-radius: 10px 0px 0 0 !important;
}
#table-border-secondary table thead tr th:last-child{
  border-radius: 0px 10px 0 0 !important;
}


/*-----Tabs-----*/
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: var(--main-color-orange);
  text-shadow: 0 0 .25px currentColor;
}
.ant-tabs-ink-bar{
  background: var(--main-color-orange) !important;
}
.ant-tabs .ant-tabs-tab:hover{
  color: var(--main-color-orange);
}

/*------Dashboards ------*/

:where(.css-dev-only-do-not-override-5wsri9).ant-collapse>.ant-collapse-item >.ant-collapse-header{
  
  border-radius:0 !important;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  -webkit-box-shadow: 4px 20 20px 0px rgba(0,0,0,0.25) !important;
  -moz-box-shadow: 4px 20px 20px 0px rgba(0,0,0,0.25) !important;
  box-shadow: 4px 20px 20px 0px rgba(0,0,0,0.25) !important;
  border-radius: 0 0 30px 30px !important; 
}
:where(.css-5wsri9).ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  -webkit-box-shadow: 4px 20 20px 0px rgba(0,0,0,0.25) !important;
  -moz-box-shadow: 4px 20px 20px 0px rgba(0,0,0,0.25) !important;
  box-shadow: 4px 20px 20px 0px rgba(0,0,0,0.25) !important;
  border-radius: 0 0 30px 30px !important; 
}
:where(.css-dev-only-do-not-override-5wsri9).ant-collapse .ant-collapse-content{
  border-top: 0;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-btn-color-dangerous.ant-btn-variant-outlined{
  border: 0 !important;   
  box-shadow: none;
  margin: 0 10px 0 0;
  padding: 0;
}
:where(.css-dev-only-do-not-override-5wsri9).ant-btn-color-dangerous{
  color: var(--main-color-purple) !important;
}
:where(.css-5wsri9).ant-btn-color-dangerous{
  color: var(--main-color-purple) !important;
}
/*.content-select-value .ant-select-selection-overflow-item:first-child {
  display: none !important;
}*/

/*------- Start Media Queries -------*/

.ant-layout-sider-collapsed a{
  color: transparent !important;
}
@media (max-width: 1400px) {
  aside .ant-menu-root {
    margin-top: 27px;
    max-height: 75vh;    
  } 
}

@media (max-width: 1600px) {
  /* .yp-grid-cards {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  } */  
}

@media (max-width: 1200px) {
  .yp-strong-value {
    font-size: 25px;
  }

  .yp-strong-value-small {
    font-size: 25px;
  }
}

@media (max-width: 923px) {
  /* .yp-grid-cards {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  } */
}

@media (max-width: 768px) {
  body{
    overflow:initial;
  }
}

@media (max-width: 360px) {
  /* .yp-grid-cards {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  } */
}



/*------- End Media Queries -------*/
