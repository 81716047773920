
.content {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.title {
  margin-top: 30px;
}

.avatar {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.avatar > p {
  margin-left: 20px;
}

.api-key-container {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.api-key-container p {
  margin-right: 5px; /* Espacio entre el texto del API Key y el icono de copia */
}

.api-key-account {
  margin-top: 15px; /* Espacio entre el API Key y la sección de Account */
}

ul {
  padding-left: 20px;
}

ul li {
  list-style-type: disc;
  word-wrap: break-word; /* Permite que el texto se divida y ajuste dentro del contenedor */
  max-width: 200px; /* Ajusta el valor según sea necesario */
}

p {
  margin: 0;
  padding: 0;
  word-wrap: break-word; /* Permite que el texto se divida y ajuste dentro del contenedor */
  /* max-width: 300px; Ajusta el valor según sea necesario */
}