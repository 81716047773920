.title-country {
  margin-left: 10px;
}

.detail {
  margin: 0;
}

.detail span {
  color: var(--main-color-gray);
  margin-left: 5px;
}

.total {
  color: var(--main-color-orange) !important;
}

.falg {
  display: flex;
  align-items: center;
}

.prefix {
  margin-left: 5px;
  font-weight: bold;
}

.table {
  border: 1px solid #f0f0f0;
  border-left: 4px solid var(--main-color-purple);
}

.table-header {
  border-bottom: 1px solid #f0f0f0;
}

.table-header > th {
  padding: 15px 25px;
}

.table-body > td {
  padding: 5px 25px;
}
