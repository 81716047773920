.phone {  
  width: 100%;
  height: 220px;
  display: flex;
  padding: 20px;
}

.phone-field-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 20px;
}

.phone-field {
  display: flex;
}

.wildcards {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.wildcard-title {
  display: flex;
}

.wildcard-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
}

.wildcard-content {
  display: flex;
  align-items: center;
}

.table-fields {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #f0f0f0;
  width: 100%;
  margin-top: 10px;
}

.table-fields-header {
  border-bottom: 1px solid #f0f0f0;
  background: #f5f2f2;
}

.table-fields-body {
  border-bottom: 1px solid #f0f0f0;
}

.table-fields-body > td {
  padding: 5px;
  text-align: center;
}

.table-fields-body > td > p {
  margin: 0;
}