.phase-number {
  background: #dedede;
  height: 20px;
  padding: 3px 5px;
  color: var(--main-color-purple);
  font-weight: bold;
}

.schedule-card {
  border-top: 10px solid var(--main-color-gray);
  width: 800px;
  margin-bottom: 10px;
}

.name {
  margin-top: 4px;
  font-weight: bold;
}

.addPhase {
  text-align: end;
}