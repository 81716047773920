.filters {
  display: flex;
  align-items: center;
}

.filters > p {
  margin: 0;
  margin-right: 10px;
}

.country-select {
  margin-right: 20px;
}

button {
  margin-right: 20px;
}