.card-title {
  display: flex;
}

.card-title > p {
  margin: 0;
}

.dashboard-card-spinner {
  margin: 5px;
  width: 100%;
}

.dashboard-body-card-single {
  grid-column: auto;
}

.dashboard-card-body {
  width: 100%;
}

.dashboard-card-body > p {
  text-align: center;
  font-size: 2.2em;
  margin: 0;
  font-weight: 300;
  color: var(--main-color-gray);
}

.dashboard-card-body > p > span {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--main-color-purple);
}