.card-title {
  display: flex;
}

.card-title > p {
  margin: 0;
}

.dashboard-card-spinner {
  margin: 5px;
  width: 100%;
}

.dashboard-body-card-single {
  grid-column: auto
}

.dashboard-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-card-body-metric {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dashboard-card-body-metric > p{
  margin: 0;
  font-weight: bold;
  font-size: 17px;
}

.dashboard-card-body-metric-value {
  display: flex;
  padding: 2px 10px;
  width: 90px;
  border-radius: 20px;
  color: var(--main-color-white);
  align-items: center;
  justify-content: center;
}

.metric-value-succesfull {
  background: var(--main-color-magenta);
}

.metric-value-error {
  background: var(--main-color-red);
}

.metric-value-convertion {
  background: var(--main-color-orange);
}

.dashboard-card-body-period {
  font-size: 20px !important;
  font-weight: bold !important;
  color: var(--main-color-purple) !important;
  margin-top: 40px;
}

.dashboard-card-body-report-button {
  padding: 10px 80px;
  background: var(--main-color-orange);
  color: var(--main-color-purple);
  margin-top: 20px;
}

.dashboard-card-body-balance-button {
  margin-top: 40px;
  padding: 4px 50px;
  background: rgba(var(--main-color-gray-rgb),.2);
  color: var(--main-color-purple);
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
}

