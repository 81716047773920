.file-template {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.file-dragger {
  display: flex;
}

.file-dragger-button {
  background: var(--main-color-purple) !important;
  border: 4px solid var(--main-color-purple-ligth) !important;
  color: var(--main-color-white);
}

.file-dragger-button > svg {
  margin-bottom: 4px;
}

.alert-rows {
  display: flex;
  margin-top: 10px;
}