.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-info {
  display: flex;
}

.content-value {
  display: flex;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
}