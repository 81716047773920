.cover {
  background: var(--main-color-purple); 
  border-radius: 20px 20px 0 0 !important;
  height: 170px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}


.button-create {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background: var(--main-color-purple-ligth);
  color: var(--main-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-create:hover {
  background: #0092FF;
}

.boddy-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}