.input-phone{
    width: 100%;
}
#user-cancel{
    background: var(--main-color-white);
    border: 1px solid var(--main-color-button-primary) !important;
    color: var(--main-color-button-primary) !important;
    transition-duration: 0.4s;
}

#user-cancel:hover {
    color: var(var(--main-color-button-secondary)) !important;
    border-color: var(var(--main-color-button-secondary)) !important;
}

#user-create-user{
    background: var(--main-color-button-primary);
    color:var(--main-color-white) !important;
    transition-duration: 0.4s;
}

#user-create-user:hover {
    background: var(--main-color-button-secondary) !important;
    border: 1px solid var(--main-color-button-secondary) !important;
}
  