.filter-container {
  background-color:  rgba(var(--main-color-gray-rgb), .2);
  position: relative;
  width: 100%;
}

.custom-collapse .custom-panel { 
  border-radius: 0 !important;  
  left: 0;
  position: absolute; 
  top: 0px; 
  width: 100%;
  z-index: 10;  
}  



.custom-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;  
}

.custom-collapse .ant-collapse-content{
  border-radius: 0 0 30px 30px; 
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
}

.filter-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;  
}

.filter-row {
  align-items: center;
  display: flex;  
  justify-content: space-between;
  gap: 16px;
}

.content-buttons-modal{
  display: flex;
  float: right;
  height: 43px;
  position: relative;
  z-index: 99;    
}


.content-buttons-modal span{
  color: var(--main-color-button-primary);
  font-size: 14px;
  font-weight: 400;
}

.filter-button-end{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.button-add{
  border:0 !important;
  color: var(--main-color-button-primary);
  font-size: 16px;
  font-weight: bold;
  padding: 0;
}
.button-done{
  background: var(--main-color-button-primary); 
  color:var(--main-color-white) !important;
  transition-duration: 0.4s;
}
.button-done:hover{
  background: var(--main-color-button-secondary) !important;
  border: 1px solid var(--main-color-button-primary) !important;
}

