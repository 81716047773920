.message-area {
  border: 1px solid var(--main-color-gray-ligth);
  border-radius: 2px;
  min-height: 160px;  /* if you want Flexible textarea/div then give min-height instead of height */
  overflow: auto;
  padding: 15px 15px;
  resize: both;
  width: 500px;
  font-size:15px;
  color:#000;
}

.message-area:disabled {
  cursor: not-allowed;
}

.message-area:focus {
  border-color: var(--main-color-gray-ligth);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(255 197 0 / 20%);
}

.message-area-wildcard {
  display: inline-flex;
  border-radius: 10px;
  padding: 4px 10px;
  text-align: left;
  color: var(--main-color-white);
  background-color: var(--main-color-purple);
  margin-bottom: 2px;
}

.message-area-wildcard-delete {
  background: transparent;
  border: none;
  cursor: pointer;
  align-self: center;
  font-size: 25px;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 0;
  line-height: 9px;
  margin: 0;
}

.message-area-wildcard-shortlink {
  color: var(--main-color-purple) !important;
  background-color: var(--main-color-orange) !important;
}

.wildcards-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  max-width: 510px;
}

.wildcards {
  display: inline-block;
  border-radius: 10px;
  min-width: 110px;
  padding: 4px 10px;
  text-align: center;
  color: var(--main-color-white);
  cursor: pointer;
  background-color: var(--main-color-purple);
}

.wildcards:hover {
  font-weight: bold;
}

.wildcard-shortlink {
  color: var(--main-color-purple) !important;
  background-color: var(--main-color-orange) !important;
}

.wildcard-shortlink:hover {
  color: var(--main-color-black) !important;
}