.card-title {
  display: flex;
}

.card-title > p {
  margin: 0;
}

.dashboard-card-spinner {
  margin: 5px;
  width: 100%;
}

.dashboard-body-card-double {
  grid-column: auto / span 2;
}

.dashboard-card-body {
  width: 100%;
}

.dashboard-card-body-totals {
  display: flex;
  justify-content: space-around;
}

.dashboard-card-body-totals-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-card-body-totals-value > p {
  text-align: center;
  font-size: 2.0rem;
  margin: 0;
  font-weight: 300;
  color: var(--main-color-gray);
}

.dashboard-card-body-totals-value > span {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--main-color-purple);
}