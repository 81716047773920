.dashboard-menu-filters {
  margin-right: 10px;
  display: flex;
  align-items: end;
}

.dashboard-menu-filters-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-left: 20px;
}

.dashboard-menu-filters-form > p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--main-color-purple);
}

.dashboard-menu-filters-form > div {
  display: flex;
  align-items: center;
}