.text-aling-right {
  text-align: right;
}

.title-star {
  color: var(--main-color-orange);
  margin-left: 5px;
}

.date strong {
  font-size: 11px;
}

.details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}



