.audio-content {
  display: flex;
  width: 300px;
  background: var(--main-color-purple-gradient);
  padding: 10px 30px;
  border-radius: 20px;
  border: 1px solid var(--main-color-purple-gradient);
  justify-content: space-between;
  align-items: center;
}

.audio-content > a {
  color: var(--main-color-white);
}

.audio-content > p {
  color: var(--main-color-white);
  margin: 0;
}