.summary-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.summary-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.message-information {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 300px;
}

.message-information-icon {
  margin-right: 15px;
}

.message-information-text {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 100%;
}

.message-information-text > p {
  margin-bottom: 2px;
  color: var(--main-color-gray);
}

.message-information-text-value {
  font-size: 20px;
}

.red-color > p {
  color: var(--main-color-red);
}

.orange-color > p {
  color: var(--main-color-orange);
}

.name-campaign{  
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word; 
  width:280px;
}