.title-account {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.img {
  margin-bottom: 20px;
}

.detail {
  margin: 0;
}
.yp-subtitle{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  word-break: break-word;  
}

.card-buttons {
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
} 

.right-buttons {
  display: flex;
  gap: 10px; /* Espacio entre los botones de la derecha */
  padding-right: 180px;
}

.fa-icon {
  font-size: 1.2em;
}

.fa-icon:hover {
  color: #fff; /* Mantener el color blanco al pasar el mouse */
}