.summary-content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.summary-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.message-information {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 300px;
}

.message-information-details-text {
  padding: 5px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-top: 10px;
}

.message-information-icon {
  margin-right: 15px;
}

.message-information-text {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 100%;
}

.message-information-text > p {
  margin-bottom: 2px;
  color: var(--main-color-gray);
  font-size: 20px;
}

.message-information-text-value {
  font-size: 20px;
}

.red-color > p {
  color: var(--main-color-red);
}

.orange-color > p {
  color: var(--main-color-orange);
}

.message-area-wildcard {
  display: inline-flex;
  border-radius: 10px;
  padding: 4px 10px;
  text-align: left;
  color: var(--main-color-white);
  background-color: var(--main-color-purple);
  margin-bottom: 2px;
}

.message-information-messages {
  margin-top: 10px;
}