.test-message-content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.test-message-info {
  width: 40%;
}

.test-message-phone {
  width: 60%;
}

.message-information {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 250px;
}

.message-information-icon {
  margin-right: 15px;
}

.message-information-text {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 100%;
}

.message-information-text > p {
  margin-bottom: 2px;
  color: var(--main-color-gray);
}

.message-information-text-red {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 250px;
}

.message-information-text-red > p{
  margin-bottom: 2px;
  color: var(--main-color-red);
}

.message-information-text-orange {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 250px;
}

.message-information-text-orange > p{
  margin-bottom: 2px;
  color: var(--main-color-orange);
}

.message-information-text-value {
  font-size: 25px;
}

.message-information-text-cuntry-value {
  font-size: 20px;
  margin-top: 10px;
}

.smartphone-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.smartphone {
  position: relative;
  height: 460px;
  width: 250px;
  border: 1px solid rgba(var(--main-color-gray-rgb),.4);
  border-radius: 30px 30px 30px 30px;
  padding-top: 60px;
  padding-left: 20px;
  margin-top: 20px;
  background: #fdfdfd;
  box-shadow: 3px 3px 5px 1px rgba(var(--main-color-gray-rgb),.3);
  -webkit-box-shadow: 3px 3px 5px 1px rgba(var(--main-color-gray-rgb),.3);
}
 
.smartphone::before {
  content: '';
  position: absolute;
  top: 30px;
  left: 70px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgba(var(--main-color-gray-rgb),.8);
}
 
.smartphone::after {
  content: '';
  position: absolute;
  top: 30px;
  left: 90px;
  width: 90px;
  height: 5px;
  background: rgba(var(--main-color-gray-rgb),.8);
}
 
.smartphone-screen {
  height: 320px;
  width: 210px;
  border: 1px solid rgba(var(--main-color-gray-rgb),.3);
  padding: 10px 5px 5px 15px;
  background: var(--main-color-white);
  overflow-y: scroll;
}
 
.smartphone-message {
  padding: 10px;
  background: rgba(var(--main-color-gray-rgb),.3);
  color: var(--main-color-black);
  border-radius: 8px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
}

.smartphone-message > p{
  margin: 0;
}

.smartphone-content-button {
  display: flex;
  justify-content: center;
  width: 210px;
  height: 40px;
  margin-top: 10px;
}

.smartphone-content-button > button {
  margin: 0;
}

.customInputDisabled {
  opacity: 0.4 !important;
 }
 
.message-sender-tooltip {
  padding-top: 35px;
}