.dashboard-home {
  display: flex;
  flex-direction: column;
  background: #f7f2f2;
  overflow-y: scroll;
  height: calc(100vh - 100px);
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  padding-left: 10px;
  padding-top: 10px;
}

.dashboard-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;
}

.dashboard-header-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.dashboard-header-buttons {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 0;
}

.dashboard-header-button {
  margin-left: 10px;
  padding: 2px 30px;
  background: rgba(var(--main-color-gray-rgb),.2);
  color: var(--main-color-purple);
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
}

.dashboard-menu {
  border-radius: 20px 20px 0 0;
  display: flex;
  width: 100%;
  background: var(--main-color-white);
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  z-index: 9;
}

.dashboard-body {
  background: var(--main-color-white);
  border-radius: 0 0 20px 20px;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); 
  grid-gap: 10px;
  padding: 10px 0;
  width: 100%;
}