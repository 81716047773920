.dashboard-delivery-content{
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 16px; 
}
.dashboard-delivery-successful, .dashboard-delivery-failed, .dashboard-delivery-convertion-rate{
  border-radius: 10px;  
  color: var(--main-color-black);
  font-weight: bold;
  text-align: center;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  padding:16px;
}
.dashboard-delivery-successful .dashboard-card-body-metric-value{
  color: var(--main-color-green);
}
.dashboard-delivery-failed .dashboard-card-body-metric-value{
  color: var(--main-color-red);
}
.dashboard-delivery-convertion-rate .dashboard-card-body-metric-value{
  color: var(--main-color-orange);
}
.dashboard-delivery-title{
  color: var(--main-color-purple);
  font-size: 18px;
  font-weight: bold;
  padding:20px 0 10px;
  text-align: center;
}
.dashboard-delivery-title-card{
  font-size: 14px;
}
.dashboard-card-body-metric-value{
  font-size: 24px;
}