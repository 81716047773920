.step-content {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
}

.step-buttons-container{
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
}

.step-buttons {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.yp-button {
  border: none;
  font-weight:bold;
  padding:4px 20px;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.yp-button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.yp-button-optional {
  background: var(--main-color-purple-gradient);
  border: 1px solid var(--main-color-purple);
  color: var(--main-color-white);
  transition-duration: 0.4s;
}

.yp-button-optional:hover {
  background: rgba(var(--main-color-purple-rgb), 0.7);
  border-color: var(--main-color-purple);
}

.yp-button-optional:disabled {
  background: rgba(var(--main-color-purple-rgb), 0.5);
  color:var(--main-color-white);
}

.yp-button-primary {
  background: var(--main-color-orange-gradient);
  color:var(--main-color-white) !important;
  transition-duration: 0.4s;
}

.yp-button-primary:hover {
  background: var(--main-color-button-secondary);
  border: 1px solid var(--main-color-button-secondary);
}

.yp-button-primary:disabled {
  background: rgba(var(--main-color-orange-rgb), 0.3);
}