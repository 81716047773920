.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  overflow-y: scroll;
}

.container-header {
  margin: 30px 50px 10px 50px;
}

.container-header-title {
  color: var(--main-color-purple);
}

.container-body {
  margin: 0 50px;
}