.phone {  
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.phone-field-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.phone-content {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.phone-field {
  display: flex;
  align-items: center;
}

.wildcards {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.wildcard-title {
  display: flex;
}

.wildcard-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wildcard-content {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.table-fields-header {
  border-bottom: 1px solid #f0f0f0;
  background: var(--main-color-purple);
  color: var(--main-color-white);
}