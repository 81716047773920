.test-message-content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.test-message-info {
  width: 40%;
}

.test-message-preview {
  width: 60%;
}

.message-information {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 250px;
}

.message-information-icon {
  margin-right: 15px;
}

.message-information-text {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 100%;
}

.message-information-text > p {
  margin-bottom: 2px;
  color: var(--main-color-gray);
}

.message-information-text-red {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 250px;
}

.message-information-text-red > p{
  margin-bottom: 2px;
  color: var(--main-color-red);
}

.message-information-text-orange {
  margin-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(var(--main-color-gray-rgb),.2);
  width: 250px;
}

.message-information-text-orange > p{
  margin-bottom: 2px;
  color: var(--main-color-orange);
}

.message-information-text-value {
  font-size: 25px;
}

.message-information-text-cuntry-value {
  font-size: 20px;
  margin-top: 10px;
}

.test-message-audio {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-message-preview-button {
  width: 100%;
  margin-bottom: 10px;
}

.test-message-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.test-message-send-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customInputDisabled {
  opacity: 0.4 !important;
  width: 230px !important;
}
.customInputEnabled{
  width: 230px !important;
}
 
.message-sender-tooltip {
  padding-top: 35px;
}
.message-sender-tooltip-voice{  
  justify-content: right;
  display: flex;
  padding-right: 14px;
  padding-top:85px;
}
.box-phone{
  padding:0;
}
.box-row-phone{
  margin: 0 auto !important;
  min-width: 345px !important;
  max-width: 345px !important;
}
.test-message-phone{
  justify-content: end;
}