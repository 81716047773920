.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-title {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  margin-bottom: 10px;
  
}

.content-title > p {
  margin: 0;
  font-weight: bold;
  color: var(--main-color-purple);
}

.content-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.content-formTitle{
  margin-bottom: 20px;
}

.status-completed {
  background-color: rgba(0, 105, 120, 0.25); /* Completed */
  color: black;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.status-ongoing {
  background-color: rgba(255, 159, 0, 0.25); /* Ongoing */
  color: black;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.status-canceled {
  background-color: rgba(191, 77, 71, 0.25); /* Canceled */
  color: black;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.status-schedule {
  background-color: rgba(184, 122, 20, 0.25); /* Schedule - #B87A14 */
  color: black;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.status-closing {
  background-color: rgba(204, 204, 204, 0.25); /* Closing - #CCCCCC */
  color: black;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}
