.rates-container {
  height: calc(100vh - 100px);
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  padding: 10px;
  overflow-y: scroll;
}

.pagination-container {
  display: flex;
  flex-direction: row-reverse;
}

.table-container {
  margin: 10px 0;
}