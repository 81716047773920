.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.content-title > p {
  margin: 0;
  font-weight: bold;
  color: var(--main-color-purple);
}

.content-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.name-table{
  word-break: break-word;
}