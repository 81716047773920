.card-filter {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  background: var(--main-color-white);
  border: 1px solid rgba(var(--main-color-black-rgb),.2);
}

.card-filter-head {
  min-height: 36px;
  margin-bottom: -1px;
  padding: 0 12px;
  color: #a9a2a2;
  font-weight: 600;
  background: 0 0;
  border-radius: 2px 2px 0 0;
  height: 57px;
}

.card-filter-head-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-filter-head-title {
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-filter-body {
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  padding: 10px;
  height: calc(100vh - 170px);
  overflow-y:auto;
  overflow-x:hidden;
  background: #fafafa;
}

@media (max-width: 767px) {
  .card-filter-body {
    height: initial;
    overflow-y:initial;
    overflow-x:initial;
    padding: 5px;
  }
}

@media (max-width: 1200px) {
  .card-filter-body {
    padding: 5px;
  }
}