.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 25px;
}

.content-title > p {
  display: -webkit-box;
  margin: 0;
  font-weight: bold;
  color: var(--main-color-purple);
  overflow: hidden;
  word-break: break-word; 
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
 
}

.content-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-modify {
  background: var(--main-color-gray-ligth) !important;
  text-align: left !important;
  border: 1px solid var(--main-color-gray-ligth) !important;
}

.table-modify > th {
  padding: 10px 20px;
}

.table-modify > th > label {
  margin-left: 15px;
}

.table-modify > th > a {
  color: var(--main-color-purple);
  margin-left: 20px;
}

.table-modify > th > a > svg {
  margin: 0 10px;
}

.table-fields-phone-header {
  background: var(--main-color-purple);
}

.btn-contact-container{
  text-align: right;
  margin-top: 10px;
  margin-right: -20px;
}
/* .content-formTitle{
  margin-bottom: 10px;
} */