.details {
  display: flex;
  padding: 5px 15px;
  flex-direction: column;
}

.border-right {
  border-right: 1px solid rgba(var(--main-color-black-rgb),.2);
}

.details-title {
  font-weight: bold;
  font-size: .8rem;
  margin-bottom: 0;
}

.details-value {
  font-size: 20px;
  color: var(--main-color-gray-ligth);
}