.filters {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.label-filter{
  margin: 0 10px 0 0;
}


