.title {
  margin-top: 30px;
}

.avatar {
  display: flex;
  align-items: center;
}

.avatar > p {
  margin-left: 20px;
  font-weight: bold;
}

.content {
  margin: 0;
}

.rates-link {
  color: var(--main-color-orange) !important;
}

.content-balance {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--main-color-purple);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer-title > h2 {
  text-align: center;
}

.drawer-balance-details {
  margin-bottom: 20px;
}

.drawer-balance-details-title {
  font-weight: bold;
  margin: 0;
}

.drawer-balance-details-value {
  color: var(--main-color-gray) !important;
  font-size: 20px !important;
  margin: 0;
}

.drawer-balance-details-value > span {
  margin-right: 10px;
}