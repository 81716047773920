.contain {
  height: calc(100vh - 120px);
  border-left: 1px solid rgba(var(--main-color-black-rgb),.2);
  border-top: 1px solid rgba(var(--main-color-black-rgb),.2);
  overflow-y: scroll;
  padding: 10px;
  justify-content: center;
}

.pagination-container {
  display: flex;
  flex-direction: row-reverse;
}

.table-container {
  margin: 10px 0;
}