.file-dragger-button {
  background: var(--main-color-purple) !important;
  border: 4px solid var(--main-color-purple-ligth) !important;
  color: var(--main-color-white);
}

.file-dragger-button > svg {
  margin-bottom: 4px;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
}