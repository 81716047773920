.card-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 15px;
}

.card-options a {
  margin: 0 0 0 15px;
  font-size: 18px;
}