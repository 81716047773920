.custom-form {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
}
/*------Cancel button------*/
.form-create-contact .contact-optional-button{
  border: 1px solid var(--main-color-button-primary) !important;
  color: var(--main-color-button-primary) !important;
  transition-duration: 0.4s;
}

.form-create-contact .contact-optional-button:hover{  
  border-color: var(--main-color-button-secondary) !important;
  color: var(--main-color-button-secondary) !important;
}

.form-create-contact .contact-primary-button{
  background: var(--main-color-button-primary); 
  color:var(--main-color-white) !important;
  transition-duration: 0.4s;
}

.form-create-contact .contact-primary-button:hover{
  background: var(--main-color-button-secondary) !important;
  border: 1px solid var(--main-color-button-primary) !important;
}