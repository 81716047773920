.title {
  text-align: center;
  margin-bottom: 40px;
}

.card-option {
  height: 350px;
  width: 240px;
  border-top: 15px solid var(--main-color-gray);
}

.card-option-selected {
  height: 350px;
  width: 240px;
  border-top: 15px solid var(--main-color-purple);
}

.option-card {
  border-top: 10px solid var(--main-color-purple);
  width: 800px;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: none;
  border: 0;
}