.content {
  width: 100%;
  height: 100%;
}

.content > p{
  color: var(--main-color-purple);
  font-weight: bold;
}

.table-fields-phone-header {
  background: var(--main-color-purple);
}