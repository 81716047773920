.dashboard-total{
  grid-column: 2 span;
  padding: 10px;
}
.card-title-total{
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}


