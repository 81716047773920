#btn-edit-account #btn-secondary-edit-account{
  border: 1px solid var(--main-color-button-primary) !important;
  color:var(--main-color-button-primary) !important;
  font-weight:700;
  line-height: 2px;
  padding: 16px 20px;
}

#btn-edit-account #btn-secondary-edit-account:hover{
  border: 1px solid var(--main-color-button-secondary) !important;
  color:var(--main-color-button-secondary) !important;
  transition-duration: .4s;
}

#btn-edit-account #btn-primary-edit-account{
  border:none !important;
  background: var(--main-color-orange-gradient) !important;
  color: var(--main-color-white);
  transition-duration: .4s;
  font-weight:700;
  line-height: 2px;
  padding: 16px 20px;
}

#btn-edit-account #btn-primary-edit-account:hover{
  background: var(--main-color-button-secondary) !important;
  border: 1px solid var(--main-color-button-secondary) !important;
}