.whatsapp-templates-table th.ant-table-cell {
  background-color: #a28aa2;
  color: white;
}

.whatsapp-templates-table tr td:first-child {  
  border-right: none !important;
  border-left: unset;
}

.facebook-login-alert .anticon-info-circle svg {
  color: #2274f6;
}

.log-into-another-account {
  border: unset !important;
  box-shadow: unset !important;
  margin-bottom: 24px;
}

.waba-card .ant-card-head-wrapper {
  height: 100%;
}
.waba-card .ant-card-head{
  border-radius: 20px 20px 0 0;
  background: var(--main-color-purple); 
  color: var(--main-color-white); 
  font-size: 14px; 
  height: 44px;
  min-height: 30px; 
  padding: 0px 10px;   
}

#go-to-template-list-button{
  background: var(--main-color-button-secondary); 
  border: 1px solid var(--main-color-button-secondary) !important;
  color: var(--main-color-white) !important; 
}
#go-to-template-list-button:hover{
  background: var(--main-color-button-primary) !important; 
  border: 1px solid var(--main-color-button-primary) !important;
  color: var(--main-color-white) !important; 
}