.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0;
}

td {
  border: 1px solid rgba(var(--main-color-gray-rgb),.2) !important;
  border-right: none !important;
  border-left: none !important;
}


.table > thead > tr {
  background: var(--main-color-purple);
  color: var(--main-color-white);
  border: 1px solid var(--main-color-purple) !important;
}

.table > thead > tr > th {
  padding: 10px 10px;
  text-align: left !important;
}

.table > thead > tr > th > label > span {
  color: var(--main-color-white);
}

.table > tbody > tr > td:first-child { 
  width: 100px;
}

.table > tbody > tr > td {
  padding: 20px;
}

.table > tbody > tr:hover {
  background-color: #f1eeee;
}