.dashboard-body-card-double {
  grid-column: auto / span 2;
}

.card-title {
  display: flex;
}

.card-title > p {
  margin: 0;
}

.dashboard-card-spinner {
  margin: 5px;
  width: 100%;
}

.dashboard-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-card-body-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-card-body-period {
  font-size: 17px;
  font-weight: bold;
  color: var(--main-color-purple);
  margin: 0;
}

.dashboard-card-body-button {
  margin-left: 10px;
  padding: 2px 30px;
  background: rgba(var(--main-color-gray-rgb),.2);
  color: var(--main-color-purple);
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
}

.dashboard-card-body-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.dashboard-card-body-table > thead > tr > th{
  text-align: left;
  font-weight: 600;
  color: var(--main-color-purple);
}

.dashboard-card-body-table > tbody > tr > td{
  border: none !important;
}

.sms-color {
  color: var(--main-color-magenta);
}

.voice-color {
  color: var(--main-color-violet);
}

.dashboard-card-body-table-variation {
  display: flex;
  width: 100%;
  height: 20px;
  border-radius: 20px;  
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 0 10px;
}

.dashboard-card-body-table-variation > p {
  margin: 0;
}

.variation-red {
  background: rgba(var(--main-color-red-rgb), 0.2);
  color: var(--main-color-red);
}

.variation-orange {
  background: rgba(var(--main-color-orange-rgb), 0.2);
  color: var(--main-color-orange);
}

.variation-green {
  background: rgba(var(--main-color-green-rbg), 0.2);
  color: var(--main-color-green);
}

@media (max-width: 1281px) {
  .dashboard-card-body-table-variation {
    display: flex;
    width: 115%;
  }
}