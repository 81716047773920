.container {
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
  justify-content: center;
}

.account-select {
  width: 320px;
  margin-right: 10px;
}