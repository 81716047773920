.file-template {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.file-dragger {
  display: flex;
}

.file-dragger-button {
  background: var(--main-color-purple) !important;
  border: 4px solid var(--main-color-purple-ligth) !important;
  color: var(--main-color-white);
}

.file-dragger-button > svg {
  margin-bottom: 4px;
}

.alert-rows {
  display: flex;
  margin-top: 10px;
}

.categories-card {
  border-radius: 12px;
}

.template-item {
  border-radius: 10px;
  background-color: #d4e1e3;
  padding: 5px 12px;
  margin: 10px 0px;
}

.template-item:hover {
  background-color: #b5d0d3;
  cursor: pointer;
}

.selected-template {
  border: 2px solid #94bdc2;
  border-radius: 10px;
  background-color: #b5d0d3;
  padding: 5px 12px;
  margin: 10px 0px;
  box-shadow: 2px 2px 4px #00000050;
}