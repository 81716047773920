.dashboard-card-total{
  border: 1px solid  var(--main-color-graphic-secondary);
  border-radius: 10px;
  color: var(--main-color-black);
  font-weight: bold;
  max-width: 153px;
  margin: 0 auto;
  text-align: center;
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  padding: 16px;
}
.dashboard-card-title{
  font-size: 16px;  
}
.dashboard-card-number{
  font-size: 24px;  
}