.card-title {
  display: flex;
}

.card-title > p {
  margin: 0;
}

.dashboard-card-spinner {
  margin: 5px;
  width: 100%;
}

.dashboard-body-card-double {
  grid-column: auto / span 2;
}

.dashboard-card-body {
  width: 100%;
}