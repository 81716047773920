.title {
  margin-top: 30px;
}

.avatar {
  display: flex;
  align-items: center;
}

.avatar > p {
  margin-left: 20px;
}