.file-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-upload-file{
  display: flex;
}
.title-text{
  align-items: center;
  display: flex;
  font-weight: bold;  
}
.title-text p{
  padding-left: 10px;
}

.file-template-box .box-button span{
  color: var(--main-color-purple-ligth);
  display: block;
  font-weight: 400;
  margin: 10px 0;
  text-transform: none;  
}
.file-template-box .box-button span:hover{
  opacity: 0.8;
}
.box-button{
  align-items: baseline;
  display: flex;
  flex-direction: column;  
}
.box-button svg{
  margin-right: 15px;
}
.box-edit-rates{
  display: flex;
  justify-content: space-between;
}
.file-template-box{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.see-rates{
  position: relative;
}

.see-rates:hover{
  opacity: 0.8;
}

.see-rates button{
  align-items: center;
  display: flex;
  margin-right: 0px;
}

.see-rates svg{
  margin-left: 20px;
}

.see-rates:hover::before{
  width: 100%;
}

.see-rates::before{
  background: var(--main-color-black);
  bottom: 0;
  content: '';
  height: 1px;
  position: absolute;
  right: 0;
  transition: width 0.3s ease-in-out;
  width: 0%;
}