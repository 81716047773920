#categoryAutoAssign .ant-checkbox-wrapper {
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
}

#categoryAutoAssign .ant-checkbox-wrapper .ant-checkbox,
#categoryAutoAssign .ant-checkbox-wrapper .ant-checkbox-inner,
#categoryAutoAssign .ant-checkbox-wrapper .ant-checkbox input {
  height: 25px;
  width: 25px;
}

#categoryAutoAssign .ant-checkbox-wrapper .ant-checkbox-inner::after {
  left: 30% !important;
}

.category-card {
  border-radius: 12px;
}

.category-card .ant-card-body {
  padding: 10px 14px;
}

.template-url-field {
  border-radius: 5px;
}

.message-form-item {
  margin-bottom: 8px;
}

.template-suggested-replies-field {
  margin-bottom: unset;
}

.action-container {
  border: 1px solid #f8f8f8;
  width: 100%;
  padding: 5px;
  height: 100%;
}

.template-url-field .ant-col.ant-form-item-control {
  width: 100% !important;
}

.template-url-field .inputs {
  margin-left: 30px;
  margin-top: -46px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.template-suggested-replies-field .group-options {
  margin-top: -28px;
  text-align: center;
}

.template-suggested-replies-field .ant-row.ant-form-item {
  margin-bottom: unset;
}

#suggestedReplies .ant-checkbox,
#suggestedReplies .ant-checkbox-inner,
#suggestedReplies .ant-checkbox input,
#url .ant-checkbox,
#url .ant-checkbox-inner,
#url .ant-checkbox input {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  border-color: var(--main-color-orange);
}

#suggestedReplies .ant-checkbox-inner.ant-checkbox-checked,
#url .ant-checkbox-inner.ant-checkbox-checked {
  border-color: var(--main-color-purple) !important;
}

.template-suggested-replies-field .ant-checkbox-wrapper .ant-checkbox-inner::after,
.template-url-field .ant-checkbox-wrapper .ant-checkbox-inner::after {
  left: 30% !important;
}

.category-card .ant-card-head {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--main-color-purple);
  color: white;
  text-align: center;
}

.category-section {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.form-group label {
  margin-left: 8px;
  flex: 1;
}

.info-icon {
  margin-left: 8px;
}

.category-card {
  transition: background-color 0.3s;
}

.category-card.selected {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

/* Phone styles */
.device-mockup {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
}

.device-frame {
  width: 230px;
  height: 100%;
  border: 8px solid black;
  border-top-width: 8px;
  border-radius: 30px;
  background-color: #fff;
  position: relative;
}

.notch {
  position: absolute;
  top: -5px;
  left: 50%;
  width: 120px;
  height: 22px;
  background: #000;
  border-radius: 5px;
  transform: translateX(-50%);
}

.device-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 14px 8px 14px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}

.contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.person-avatar {
  width: 25px;
  height: 25px;
  background-color: #ccc;
  border-radius: 100%;
}

.header-icons {
  display: flex;
  gap: 10px;
}

.chat-preview {
  flex: 1;
  padding: 20px;
  background-color: #ece5dd;
}

.message-card {
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.chat-bubble {
  position: relative;
  margin-top: 10px;
}

.inner-message-card {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.bubble-arrow {
  position: absolute;
  bottom: -20px;
  left: 130px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}

.message-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.footer-text, .footer-time {
  display: block;
  color: #b1b1b1;
  font-size: 10px;
  margin-top: 5px;
}

.button-group {
  display: flex;
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.reply-button, .action-button {
  width: 48%;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
}

.action-button {
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  padding: 0px 10px;
  text-overflow: ellipsis;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 50px 10px 10px 10px;
  background-color: #ece5dd;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px; 
}

.chat-input input {
  flex: 1;
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.chat-input input:disabled {
  flex: 1;
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: white;
}



.reply-button {
  background-color: #1890ff;
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
}

.reply-button:hover {
  background-color: #40a9ff;
}

/* Styles for URL buttons */
.url-group {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.url-button a {
  color: #1890ff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.url-button a:hover {
  color: #40a9ff;
}

.url-button {
  background-color: #f0f2f5;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
}