.container {
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: row-reverse;
  padding-right: 40px;
}

.container > a {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px;
  color: var(--main-color-violet);
  border-radius: 6px;
  border: 1px solid var(--main-color-violet);
}
