.dashboard-header-spinner {
  width: 100%;
}

.dashboard-header-spinner + div {
  width: 100% !important;
}

.dashboard-header-card {
  border: 1px solid rgba(var(--main-color-gray-rgb), 0.2);
  border-radius: 20px; 
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  width: 100%;
  align-items: center;
  background: var(--main-color-white);
  -webkit-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.25);
}

.dashboard-header-card-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dashboard-header-card-title > p {
  margin: 0;
  font-weight: bold;
}

.dashboard-header-card-value {
  display: flex;
  width: 100%;
  justify-content: center;
}

.dashboard-header-card-value > p {
  font-size: 2.2em;
  margin: 0;
  font-weight: 300;
  color: var(--main-color-gray);
}

.dashboard-header-card-footer {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.dashboard-header-card-footer > p {
  margin: 0;
  font-weight: bold;
}

.dashboard-header-card-footer-variation {
  height: 30px;
  border-radius: 20px;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-header-card-footer-variation > p {
  margin: 0;
  padding: 0 10px;
}

.variation-red {
  background: rgba(var(--main-color-red-rgb), 0.2);
  color: var(--main-color-red);
}

.variation-orange {
  background: rgba(var(--main-color-orange-rgb), 0.2);
  color: var(--main-color-orange);
}

.variation-green {
  background: rgba(var(--main-color-green-rbg), 0.2);
  color: var(--main-color-green);
}

.dashboard-header-button { 
  margin-left: 10px;
  padding: 4px 4px;
  background: rgba(var(--main-color-gray-rgb),.2);
  color: var(--main-color-purple);
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8rem;
}



